html {
  height: 100%;
  font-size: 62.5%;
}
body {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  font-size: tovw(18);
  color: #fff;
  font-family: $roboto;
  background-color: #000;
  padding-top: tovw(73);
  &.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    height: 100% !important;
  }
}
img {
  max-width: 100% !important;
}
#root {
  position: relative;
  min-height: 100%;
}
button {
  outline: none !important;
}
a {
  color: #fff;
  transition: color 0.25s ease;
  outline: none !important;
  &:hover {
    color: #000;
    text-decoration: none;
  }
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 1rem;
  padding-right: 1rem;
}
.container {
  max-width: 100% !important;
  padding-left: 1rem;
  padding-right: 1rem;
  &--content {
    max-width: tovw(1400) !important;
  }
}
.row {
  margin-left: -1rem;
  margin-right: -1rem;
}
.swal2-shown,
.ReactModal__Body--open {
  #root {
    //filter: blur(5px);
  }
}
.swal2-container.swal2-backdrop-show {
  background-color: rgba(0, 0, 0, 0.6) !important;
  overflow-y: auto !important;
  .swal2-popup {
    display: flex;
  }
}
.swal2-container {
  .swal2-popup {
    height: auto !important;
    width: tovw(568) !important;
    min-height: tovw(350) !important;
    background: url("/images/popup-s.png?v=1") !important;
    background-size: 100% 100% !important;
    padding-top: 4rem;
    padding-bottom: 2rem;
    // border-radius: 1rem !important;
    .swal2-close,
    .close {
      position: absolute;
      width: 14px;
      height: 14px;
      // background: url(#{$cdnUrl}/images/close.png) no-repeat center;
      background-size: 100%;
      top: 10px;
      right: 10px;
      opacity: 1;
      font-size: 4rem;
      outline: none !important;
      color: #000 !important;
      font-family: serif;
      font-weight: 300;
      z-index: 9;
      text-indent: -9999px;
    }
    #swal2-content {
      font-size: tovw(20);
      color: #000;
      line-height: 150%;
      text-transform: uppercase;
      p {
        font-size: tovw(20);
        color: #000;
        line-height: 150%;
        text-transform: uppercase;
      }
    }
    #swal2-title,
    h2 {
      font-size: tovw(50);
      font-weight: normal;
      text-align: center;
      text-transform: uppercase;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      margin-bottom: tovw(110);
      padding-left: 1rem;
      padding-right: 1rem;
      color: #ffffff;
      justify-content: center;
      font-family: $font-heading;
      // height: tovw(95);
      // line-height: tovw(95);
      // &::before {
      //   content: "";
      //   position: absolute;
      //   top: 50%;
      //   left: 50%;
      //   transform: translate(-50%, -50%);
      //   width: calc(100% - #{tovw(12)});
      //   height: 100%;
      //   margin-top: tovw(0);
      //   background: url(/images/bg-title.png) repeat-x;
      //   background-size: 100% 100%;
      //   z-index: -1;
      //   opacity: 0.5;
      // }
      // &::after {
      //   content: "";
      //   position: absolute;
      //   bottom: 0;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   width: 80%;
      //   height: tovw(2);
      //   background: url(/images/line.png) repeat-x;
      //   background-size: 100% 100%;
      //   margin-bottom: tovw(0);
      // }
    }
    &.popup-reward {
      #swal2-content {
        padding-top: tovw(90);
      }
    }
    &.popup-image {
      background: transparent !important;
      box-shadow: none !important;
      width: auto !important;
      padding: 0 !important;
      border-radius: 0.5vw !important;
      overflow: hidden;
      img {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
        backface-visibility: hidden;
      }
    }
  }
}
.ReactModal__Overlay {
  display: flex !important;
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0;
  left: 0 !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 10px !important;
  background: rgba(0, 0, 0, 0.8) !important;
  z-index: 1060 !important;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch !important;
}
.ReactModal__Content {
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: inherit;
  box-sizing: border-box;
  margin: auto;
  width: tovw(568) !important;
  min-height: tovw(300) !important;
  background: url("/images/popup-s.png?v=1") !important;
  background-size: 100% 100% !important;
  border-radius: 1rem !important;
  border: 0 !important;
  padding: 2rem 0 !important;
  padding-top: 0 !important;
  text-align: center;
  outline: none;
  .modal-description {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  &.modal-history {
    width: tovw(890) !important;
    min-height: tovw(530) !important;
    background: url(/images/popup2.png) !important;
    background-size: 100% 100% !important;
    .modal-description {
      width: 94%;
    }
  }
  .close {
    position: absolute;
    width: 14px;
    height: 14px;
    // background: url(#{$cdnUrl}/images/close.png) no-repeat center;
    background-size: 100%;
    top: 10px;
    right: 10px;
    opacity: 1;
    font-size: 4rem;
    outline: none !important;
    color: #000 !important;
    font-family: serif;
    font-weight: 300;
    z-index: 9;
    text-indent: -9999px;
  }
  h2 {
    font-size: tovw(50);
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: tovw(40);
    padding-left: 1rem;
    padding-right: 1rem;
    color: #ffffff;
    justify-content: center;
    font-family: $font-heading;
    // height: tovw(95);
    position: relative;
    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   width: calc(104% + #{tovw(2)});
    //   height: 100%;
    //   margin-top: tovw(-10);
    //   background: url(/images/bg-title.png) repeat-x;
    //   background-size: 100% 100%;
    //   z-index: -1;
    //   opacity: 0.5;
    // }
    // &::after {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   width: 80%;
    //   height: tovw(2);
    //   background: url(/images/line.png) repeat-x;
    //   background-size: 100% 100%;
    //   margin-bottom: tovw(9);
    // }
  }
  p,
  li {
    font-size: tovw(20);
    color: #000;
  }
}
#main-body {
  position: relative;
}
.main-content {
  position: relative;
}
.ScrollbarsCustom-Wrapper {
  right: 0 !important;
}
.ScrollbarsCustom-Track {
  width: tovw(10) !important;
  right: tovw(-35) !important;
  z-index: 1;
  background: transparent !important;
  &:before {
    content: "";
    position: absolute;
    width: 1px;
    background: #bad2f4;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }
  .modal-history & {
    right: tovw(-14) !important;
    &:before {
      background: #bad2f4;
    }
  }
}
.ScrollbarsCustom-Thumb {
  width: 100% !important;
  border-radius: 0.5vw !important;
  background: #bad2f4 !important;
  .modal-history & {
    background: #bad2f4 !important;
  }
}
#header {
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
#footer {
  position: relative;
  background: #000;
  padding-top: 1.5vw;
  padding-bottom: 1.5vw;
  .container {
    width: tovw(1320);
  }
  .footer-logo {
    display: inline-block;
    width: tovw(500);
    margin-bottom: tovw(18);
  }

  p {
    font-size: tovw(17);
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    margin-bottom: 0;
    font-family: $roboto;
  }
}
.top-bar {
  position: relative;
  height: tovw(73);
  background: url(/images/header-bg.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: tovw(3);
    background-color: #e1f1f5;
  }
  > .container {
    height: 100%;
    max-width: tovw(1620) !important;
    margin-left: auto;
    margin-right: auto;
    > .row {
      height: 100%;
    }
  }
  .col-7 {
    text-align: right;
  }
  span {
    display: inline-block;
    color: #fff;
    font-size: tovw(18);
    em {
      width: 1px;
      height: 1vw;
      background: #fff;
      display: inline-block;
      vertical-align: middle;
      margin-left: 0.5vw;
      margin-right: 0.5vw;
    }
    .text-red {
      font-size: tovw(15);
      width: tovw(26);
      height: tovw(26);
      padding-top: 1px;
      background-color: #f71f2b;
      border-radius: 50%;
      border: 1px solid #fff;
      text-align: center;
      color: #fff !important;
    }
  }
}
.nickname {
  display: inline-block;
  max-width: 8vw;
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: tovw(-3);
  margin-right: tovw(30);
}
.logo {
  display: inline-block;
  height: tovw(47);
  margin-top: tovw(8);
  img {
    display: block;
    backface-visibility: hidden;
    height: 100%;
  }
}
.menu-link {
  display: inline-block;
  font-size: tovw(22);
  text-transform: uppercase;
  color: #000;
  position: relative;
  line-height: tovw(48);
  background-color: transparent;
  font-family: $font-primary;
  font-weight: bold;
  padding: 0 tovw(22);
  border-radius: tovw(0);
  z-index: 1;
  transition: background 0.3s ease, border-radius 0.3 ease;
  &:hover {
    color: #fff;
    background-color: #04077e;
    border-radius: tovw(24);
  }
}
.btn-login {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: tovw(-110);
  transform: translateY(-50%);
  width: tovw(131);
  height: tovw(41);
  line-height: tovw(41);
  font-size: tovw(18);
  font-weight: bold;
  text-transform: uppercase;
  vertical-align: middle;
  margin-left: 0.5vw;
  text-align: center;
  color: #000;
  transition: color 0.3s ease;
  &:hover {
    color: #000;
  }
}
.btn-logout {
  position: absolute;
  top: 50%;
  right: tovw(-100);
  transform: translateY(-50%);
  padding: 0.5vw;
  font-size: tovw(18);
  font-weight: bold;
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  transition: color 0.3s ease;
  &:hover {
    color: #fff;
  }
}
.section {
  position: relative;
  &--1 {
    padding-top: tovw(20);
    padding-bottom: tovw(120);
    background: url("/images/puz_bg.png") no-repeat bottom center;
    background-size: cover;
    min-height: tovw(1006);
    overflow: hidden;
  }
}
.title-page {
  width: tovw(370);
}
.sub-title {
  font-size: tovw(18);
  color: #fff;
  font-family: $roboto;
  text-align: left;
  margin-top: tovw(20);
}
.list-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: tovw(20);
  a {
    width: tovw(230);
    margin-bottom: tovw(10);
    margin-right: tovw(10);
    font-size: tovw(20) !important;
    &:nth-child(2),
    &:nth-child(4) {
      margin-right: 0;
    }
  }
}
.rewards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: tovw(15) tovw(20) 0 tovw(20);
  background: url(/images/reward-bg.png) no-repeat;
  background-size: 100% 100%;
  margin-top: tovw(30);
  margin-bottom: tovw(40);
  &__item {
    position: relative;
    width: tovw(137);
    height: tovw(137);
    background: url(/images/item-bg.png) no-repeat;
    background-size: 100% 100%;
    margin-bottom: tovw(20);
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      height: tovw(107);
    }
    &:nth-child(1) {
      .img-puzzle {
        height: tovw(41);
      }
    }
    &:nth-child(2) {
      .img-puzzle {
        height: tovw(33);
      }
    }
    &:nth-child(3) {
      .img-puzzle {
        height: tovw(41);
      }
    }
    &:nth-child(4) {
      .img-puzzle {
        height: tovw(33);
      }
    }
    &:nth-child(5) {
      .rewards__puzzle {
        top: tovw(8);
      }
      .img-puzzle {
        height: tovw(40);
      }
    }
    &:nth-child(6) {
      .img-puzzle {
        height: tovw(33);
      }
    }
  }
  &__puzzle {
    position: absolute;
    top: tovw(10);
    left: tovw(10);
  }
  &__received {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(6, 11, 85, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: tovw(16);
    img {
      width: tovw(108);
      height: auto !important;
    }
  }
}
.reward-finish {
  padding: tovw(15) tovw(30) tovw(65) tovw(30);
  background: url(/images/reward-bg-s.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  position: relative;
  h3 {
    font-size: tovw(30);
    text-transform: uppercase;
    color: #fff;
    font-family: $iciel;
    text-shadow: 0px tovw(3) 0px rgba(79, 82, 138, 4%);
    margin-bottom: tovw(10);
  }
  img {
    height: tovw(150);
  }
  .btn {
    position: absolute;
    bottom: tovw(15);
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    &:hover {
      transform: translateY(-0.5rem) translateX(-50%);
    }
  }
  .rewards__received {
    background-color: rgba(6, 11, 85, 0.6);
  }
}
.spin {
  width: 96%;
  height: tovw(600);
  // background: url(/images/spin-bg.png) no-repeat;
  // background-size: 100% 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: tovw(-56);
    margin-top: tovw(-2);
    transform: translateX(-50%);
    width: 135%;
    height: tovw(190);
    background: url(/images/box-shadow.png) no-repeat;
    background-size: 100% 100%;
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: url(/images/spin-bg.png) no-repeat;
    background-size: 100% 100%;
    z-index: 2;
  }
  &__decor {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: tovw(1024);
    height: tovw(640);
    background: url(/images/decor-spin.png) no-repeat;
    background-size: 100% 100%;
    z-index: 1;
    display: none;
  }
  &__btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    img {
      height: tovw(59);
    }
    &--prev {
      left: tovw(-40);
    }
    &--next {
      right: tovw(-40);
    }
  }
  &__puzzle {
    position: absolute;
    z-index: 5;
    &--1 {
      top: tovw(29);
      left: tovw(31);
      img {
        height: tovw(354);
      }
    }
    &--2 {
      top: tovw(29);
      left: tovw(247);
      img {
        height: tovw(270);
      }
    }
    &--3 {
      top: tovw(29);
      right: tovw(29);
      img {
        height: tovw(353);
      }
    }
    &--4 {
      bottom: tovw(29);
      left: tovw(31);
      img {
        height: tovw(270);
      }
    }
    &--5 {
      bottom: tovw(29);
      left: tovw(341);
      img {
        height: tovw(352);
      }
    }
    &--6 {
      bottom: tovw(29);
      right: tovw(29);
      img {
        height: tovw(270);
      }
    }
    &--active {
      z-index: 5;
      filter: drop-shadow(0px 0px 20px #f1a44a) brightness(120%);

      // img {
      //   transform: scale(1.05);
      // }
    }
  }
  &__pic-num {
    font-size: tovw(38);
    text-transform: uppercase;
    font-family: $iciel;
    text-shadow: 0px tovw(6) 0px rgba(79, 82, 138, 4%);
    position: relative;
    z-index: 3;
  }
  &__lock {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 94%;
    height: 92%;
    background-color: rgba(32, 15, 66, 0.7);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: tovw(40);
    img {
      width: tovw(90);
    }
  }
  &__picture {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 94.5%;
    height: 92%;
    border-radius: tovw(40);
    overflow: hidden;
    z-index: 3;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.player1 {
  position: absolute;
  bottom: tovw(-30);
  left: tovw(-260);
  z-index: 7;
  img {
    width: tovw(450);
  }
}
.player2 {
  position: absolute;
  bottom: tovw(-150);
  right: 0;
  z-index: 4;
  img {
    width: tovw(440);
  }
}
.table-history {
  width: 100%;
  tbody {
    tr {
      border-top: 1px solid #000;
    }
  }
  tr {
    th,
    td {
      font-size: tovw(20);
      color: #000;
      padding: tovw(15) 0;
      font-family: $roboto;
      font-weight: 300;
    }
    th {
      font-weight: bold;
    }
  }
}
.match-progress {
  p {
    font-size: tovw(20);
    line-height: 1.2 !important;
    span {
      display: block;
      padding-right: tovw(36);
    }
  }
  img {
    display: inline-block;
    height: tovw(50);
    backface-visibility: hidden;
  }
  .col-5 {
    position: relative;
    &.active {
      &::before {
        content: "";
        position: absolute;
        bottom: tovw(-10);
        right: tovw(100);
        width: tovw(43);
        height: tovw(33);
        background: url(/images/check.png) no-repeat;
        background-size: 100% 100%;
        z-index: 2;
      }
    }
  }
}
.item-cover {
  img {
    height: tovw(120);
  }
}
.no-event {
  pointer-events: none;
}
