.btn {
  background: url(/images/btn-red.png) no-repeat !important;
  background-size: 100% 100% !important;
  font-size: tovw(24);
  text-transform: uppercase;
  color: #fff !important;
  height: tovw(58);
  line-height: tovw(48);
  padding: 0 tovw(15);
  outline: none !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0;
  min-width: tovw(222);
  position: relative;
  font-weight: bold;
  transition: transform 0.25s ease;
  font-family: $iciel;
  .decor {
    width: tovw(33);
    vertical-align: text-top;
    margin-right: tovw(10);
  }
  &--orange {
    background: url(/images/btn-orange.png) no-repeat !important;
    background-size: 100% 100% !important;
    min-width: tovw(250);
  }
  &--blue {
    background: url(/images/btn-green.png) no-repeat !important;
    background-size: 100% 100% !important;
  }
  &--purple {
    min-width: tovw(250);
    border: tovw(2) solid #fff !important;
    border-radius: tovw(20) !important;
    background-image: none !important;
    color: #fff !important;
  }
  @include min-screen(1200px) {
    &:hover {
      transform: translateY(-0.5rem);
    }
  }
}
.swal2-container .swal2-popup .swal2-styled.swal2-confirm,
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  background: url(/images/btn-red.png) no-repeat !important;
  background-size: 100% 100% !important;
  font-size: tovw(24);
  text-transform: uppercase;
  color: #fff !important;
  padding: tovw(8) tovw(15) tovw(17) tovw(15);
  outline: none !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0;
  min-width: tovw(222);
  position: relative;
  font-weight: bold;
  transition: transform 0.25s ease;
  font-family: $iciel;
}
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  // @extend %grayscale;
  // background: url(/images/btn-cancel.png) no-repeat !important;
  background-size: 100% 100% !important;
  color: #fff !important;
}
